var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box grow mh-15",staticStyle:{"max-width":"700px"}},[_c('div',{staticClass:"flex wrap justify-center"},[_vm._l((_vm.getSessions),function(s,index){return _c('div',{key:index + '-session',staticClass:"box grow card-base card-shadow--large m-5 clickable session-panel",class:[
          {'locked-panel' : _vm.isLocked(s)},
          {'bg-accent white-text' : _vm.selected === index}
        ],on:{"click":function($event){return _vm.onClickSession(index)}}},[_c('div',{staticClass:"p-20 flex column gaps"},[_c('h3',{staticClass:"box center",class:[s.isSpecial && _vm.selected !== index ? 'accent-text' : '']},[_vm._v(" "+_vm._s(s.name)+" "),(s.isSpecial)?_c('span',{class:[_vm.selected === index ? '' : 'accent-text']},[_vm._v(" *")]):_vm._e()]),_c('h3',{staticClass:"box center"},[_vm._v(_vm._s(s.time))]),_c('div',{staticClass:"box flex justify-space-between"},[_c('div',{staticClass:"box m-0"},[_c('span',{staticClass:"pr-6"},[_vm._v("left:")]),(!_vm.isLocked(s))?_c('span',{class:[
                { 'danger-text': s.left <= 10 && _vm.selected !== index },
                { 'warning-text': s.left > 10 && s.left < 21 && _vm.selected !== index },
                { 'success-text': s.left >= 21 && _vm.selected !== index },
                'fw-900']},[_vm._v(_vm._s(s.left))]):_c('span',{staticClass:"danger-text fw-900"},[_vm._v("0")])]),_c('div',{staticClass:"box m-0 font-size-13 center right",class:[_vm.selected === index ? '' : 'secondary-text']},[_vm._v(" "+_vm._s(s.price)+" AED")])])])])}),_vm._l((_vm.extraPanel),function(i,index){return _c('div',{key:index+ '-extra',staticClass:"box grow m-5"})})],2),_c('div',{staticClass:"mv-30 mh-15 center"},_vm._l((_vm.isSpecialSessions),function(item,index){return _c('p',{key:index+ '-special'},[_c('span',{staticClass:"fw-700 accent-text pr-6"},[_vm._v("* "+_vm._s(item.name)+":")]),_vm._v(" "+_vm._s(item.description))])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }