<template>
  <div class="flex column gaps">
    <div class="box grow border b-rad-5 card-shadow--large mh-15" style="max-width: 700px;">
      <FullCalendar
        ref="fullCalendar"
        class="calendar-wrap"
        :options='calendarOptions'></FullCalendar>
    </div>
    <div class="mh-15 flex column gaps">
      <div class="legend flex gaps">
        <div class="square sess sess-0"></div>
        <div class="box left center">More than 75% of spaces left</div>
      </div>
      <div class="legend flex gaps">
        <div class="square sess sess-1"></div>
        <div class="box left center">50% - 75% of spaces left</div>
      </div>
      <div class="legend flex gaps">
        <div class="square sess sess-2"></div>
        <div class="box left center">25% - 50% of spaces left</div>
      </div>
      <div class="legend flex gaps">
        <div class="square sess sess-3"></div>
        <div class="box left center">Less than 25% of spaces left</div>
      </div>
      <div class="legend flex gaps">
        <div class="square sess sess-4"></div>
        <div class="box left center">No spaces left</div>
      </div>
      <div class="legend flex gaps">
        <div class="square sess sess-5"></div>
        <div class="box left center">Not available for booking</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  name: 'ChangeDate',

  props: {
    calendarEventsArray: {
      type: Array,
      default: function () {
        return []
      }
    },
    day: {
      type: String,
      default: function () {
        return ''
      }
    }
  },

  data () {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        initialDate: new Date().toISOString().slice(0, 10),
        headerToolbar: {
          start: '', // will normally be on the left. if RTL, will be on the right
          center: 'title',
          end: '' // will normally be on the right. if RTL, will be on the left
        },
        dateAlignment: 'week',
        height: 'auto',
        dateClick: this.dayClick,
        editable: true,
        events: this.calendarEventsArray
      }
    }
  },

  components: {
    FullCalendar
  },

  computed: {
    ...mapGetters([
      'calendarDaysArray'
    ]),
    today () {
      return new Date().getTime()
    }
  },

  methods: {
    ...mapActions(['changetDay']),
    dayClick (arg) {
      if (new Date(arg.date).getTime() > this.today - 86400000) {
        if (new Date(arg.date).getTime() > this.today + (86400000 * 30)) {
          this.$message({
            showClose: true,
            message: 'К сожалению, вы не можете забронировать билеты более чем на месяц вперед. Попробуйте позже.',
            type: 'error'
          })
        } else {
          if (this.calendarDaysArray.includes(arg.dateStr)) {
            this.calendarOptions.events = [{ start: arg.dateStr, classNames: ['selected'], display: 'background' }]
            this.$emit('selectDate', { day: arg.dateStr, isSame: this.day === arg.dateStr })
          } else {
            this.$message({
              showClose: true,
              message: 'На эту дату еще не установлено расписание',
              type: 'error'
            })
          }
        }
      } else {
        this.$message({
          showClose: true,
          message: 'Вы не можете выбрать день который уже прошел',
          type: 'error'
        })
      }
    }
  },
  activated () {
    let calendarApi = this.$refs.fullCalendar.getApi()
    calendarApi.updateSize()
    // this.calendarOptions.events = this.calendarEventsArray
  },
  watch: {
    day: function (val, oldVal) {
      this.$nextTick(() => {
        this.calendarOptions.events = this.calendarEventsArray
      })
    }
  }
}
</script>
