<template>
<div class="payment box grow mh-15" style="max-width: 700px;">
    <div class="flex column gaps">
        <div class="box grow p-20 card-base card-shadow--large">
            <h3 class="m-0 mb-10">Purchase details:</h3>
            <div class="box grow mt-20 purchase">
                <div class="t-row flex">
                    <div class="box label">Date:</div>
                    <div class="box value">{{step0}}</div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Time:</div>
                    <div class="box value">{{change.session.time}}</div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Service:</div>
                    <div class="box value">{{change.session.name}}</div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Tickets:</div>
                    <div class="box value">{{current.ticket.persons}} {{current.ticket.name}}</div>
                </div>
                <div class="t-row flex" v-if="current.addons.length > 0">
                    <div class="box label">Addons:</div>
                    <div class="box value">
                      <div v-for="(a, index) in current.addons" :key="index + '-addons'">{{a.count}} {{a.name}}</div>
                    </div>
                </div>
                <div class="t-row flex">
                    <div class="box label">Contact:</div>
                    <div class="box value">{{current.customer.first + ' ' + current.customer.surname}}</div>
                </div>
            </div>
        </div>
        <div class="flex wrap gaps ">
            <div class="box grow bottom pb-20 pt-40 pr-10 total">
                <el-button type="primary" size="medium" @click="onClickConfirm" class="signin-btn">
                    Confirm Changes
                </el-button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ChangeConfirm',

  props: {
    change: {
      type: Object,
      default: function () {
        return {}
      }
    },
    current: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data () {
    return {}
  },

  computed: {
    step0 () {
      let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      return this.change.day !== ''
        ? new Date(this.change.day).toLocaleString('en-GB', options)
        : ''
    }
  },

  methods: {
    ...mapActions(['editBook']),
    onClickConfirm () {
      this.editBook({
        id: this.change.id,
        content: {
          day: this.change.day,
          session: {
            idx: this.change.session.idx,
            name: this.change.session.name,
            time: this.change.session.time
          }
        }
      })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'Book edited successfully ',
            type: 'success'
          })
        })
        .then(() => this.$onCommandParams('mybooks'))
    }
  }
}
</script>
