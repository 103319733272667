<template>
  <div class="box grow mh-15" style="max-width: 700px;">
    <div class="flex wrap justify-center">
      <div v-for="(s,index) in getSessions" :key="index + '-session'"
          @click="onClickSession(index)"
          class="box grow card-base card-shadow--large m-5 clickable session-panel"
          :class="[
            {'locked-panel' : isLocked(s)},
            {'bg-accent white-text' : selected === index}
          ]">
        <div class="p-20 flex column gaps">
          <h3 class="box center" :class="[s.isSpecial && selected !== index ? 'accent-text' : '']">
            {{s.name}} <span :class="[selected === index ? '' : 'accent-text']" v-if="s.isSpecial"> *</span>
          </h3>
          <h3 class="box center">{{s.time}}</h3>
          <div class="box flex justify-space-between">
            <div class="box m-0">
              <span class="pr-6">left:</span>
              <span v-if="!isLocked(s)"
                :class="[
                  { 'danger-text': s.left <= 10 && selected !== index },
                  { 'warning-text': s.left > 10 && s.left < 21 && selected !== index },
                  { 'success-text': s.left >= 21 && selected !== index },
                  'fw-900']">{{s.left}}</span>
              <span class="danger-text fw-900" v-else>0</span>
            </div>
            <div class="box m-0 font-size-13 center right"
              :class="[selected === index ? '' : 'secondary-text']"> {{s.price}} AED</div>
          </div>
        </div>
      </div>
      <div v-for="(i, index) in extraPanel" :key="index+ '-extra'" class="box grow m-5"></div>
    </div>
    <div class="mv-30 mh-15 center">
      <p v-for="(item, index) in isSpecialSessions" :key="index+ '-special'"><span class="fw-700 accent-text pr-6">* {{item.name}}:</span> {{item.description}}</p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ChangeSessions',

  props: {
    sessions: {
      type: Array,
      default: function () {
        return []
      }
    },
    day: {
      type: String,
      default: function () {
        return ''
      }
    },
    selected: {
      type: Number,
      default: function () {
        return -1
      }
    },
    blockBooking: {
      type: Number,
      default: function () {
        return 60
      }
    },
    innerWidth: {
      type: Number,
      default: function () {
        return 0
      }
    }
  },

  data () {
    return {}
  },

  computed: {
    ...mapGetters([
      'getSessionsByDay'
    ]),
    getSessions () {
      return this.getSessionsByDay[this.day] || []
    },
    isSpecialSessions () {
      return [...new Map(this.getSessions
        .filter(s => s.isSpecial)
        .map(s => [s['sessionId'], { name: s.name, description: s.description }]))
        .values()]
    },
    extraPanel () {
      if (this.innerWidth >= 750) { // 3
        return 3 - (this.getSessions.length % 3)
      } else if (this.innerWidth >= 520) { // 2
        return 2 - (this.getSessions.length % 2)
      }
      return 0
    }
  },

  methods: {
    ...mapActions(['selectSession']),
    onClickSession (index) {
      if (this.getSessions[index].left > 0 && !this.isLocked(this.getSessions[index])) {
        this.$emit('selectSession', {
          name: this.getSessions[index].name,
          time: this.getSessions[index].time,
          idx: index })
      }
    },
    isLocked (s) {
      let now = new Date()
      let today = now.toISOString().slice(0, 10)
      let startArr = s.time.split(' - ')[0].split(':')
      let start = new Date(now.setHours(startArr[0], startArr[1], 0)).getTime()
      let nowWithDelay = new Date(new Date().setMinutes(new Date().getMinutes() + this.blockBooking)).getTime()
      return s.left === 0 ||
        (this.day === today && start < nowWithDelay) ||
        (s.hasOwnProperty('block') && s.block.hasOwnProperty('isBlock') && s.block.isBlock)
    }
  }
}
</script>
