<template>
  <vue-scroll class="register-page ps" ref="scroll">
    <div class="flex column">
      <div class="mh-15 center">
        <h2 class="accent-text">Change Book</h2>
      </div>
      <el-divider class="mv-10"></el-divider>
      <div class="box mh-15" style="max-width: 700px;">
        <el-steps :active="activeStep" align-center ref="steps" class="pv-30">
          <el-step v-for="(s, index) in steps" :key="index + '-' + s.step"
            class="clickable"
            process-status="success"
            finish-status="finish"
           :title="s.step" @click.native="e => onClickStep(e, index)">
            <div slot="description" class="flex column" v-if="innerWidth >= 700">
              <div v-for="(d, idx) in descriptions[index]" :key="idx + '-desc'">
                {{d}}
              </div>
            </div>
           </el-step>
        </el-steps>
      </div>
      <div class="m-15" v-if="isNext">
        <el-button @click="selectStep(activeStep + 1)" type="primary">Next</el-button>
      </div>
      <transition name="fade" mode="out-in">
        <keep-alive>
          <app-change-date
            @selectDate="selectDate"
            :day="change.day"
            :calendarEventsArray="calendarEventsArray"
            v-if="activeStep === 0"></app-change-date>
          <app-change-session
            @selectSession="selectSession"
            v-if="activeStep === 1"
            :innerWidth="innerWidth"
            :selected="change.session.idx"
            :day="change.day"
            :blockBooking="blockBooking"></app-change-session>
          <app-change-confirm
            :change="change"
            :current="current"
            v-if="activeStep === 2"></app-change-confirm>
        </keep-alive>
      </transition>
    </div>
  </vue-scroll>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AppChangeDate from './ChangeDate.vue'
import AppChangeSession from './ChangeSession.vue'
import AppChangeConfirm from './ChangeConfirm.vue'

export default {
  name: 'Change',

  components: {
    AppChangeDate, AppChangeSession, AppChangeConfirm
  },

  data () {
    return {
      activeStep: 0,
      steps: [
        { step: 'Date' },
        { step: 'Session' },
        { step: 'Confirm' }
      ],
      innerWidth: 0,
      innerHeight: 0,
      change: {
        id: '',
        day: '',
        session: {
          idx: -1,
          name: '',
          time: ''
        }
      }
    }
  },

  computed: {
    ...mapState({
      defaultBook: state => state.booking.defaultBook,
      blockBooking: state => state.booking.settings.blockBooking
    }),
    ...mapGetters(['getBooksById', 'calendar', 'getSessionsByDay']),
    current () {
      return this.getBooksById[this.$route.params.id] || this.defaultBook
    },
    descriptions () {
      let options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
      let step0 = this.change.day !== ''
        ? new Date(this.change.day).toLocaleString('en-GB', options)
        : ''
      let step1 = [
        this.change.session.name,
        this.change.session.time
      ]
      return [step0.split(',').map(e => e.trim()), step1]
    },
    calendarEventsArray () {
      return this.calendar.map(d => {
        if (this.change.day === d.day) {
          return {
            start: d.day,
            display: 'background',
            classNames: ['sess selected'],
            title: d.day.slice(-2)
          }
        } else {
          let sess = this.getSessionsByDay[d.day]
            .reduce((a, c) => {
              return {
                left: a.left + c.left,
                capacity: a.capacity + c.capacity
              }
            }, { left: 0, capacity: 0 })

          let per = Math.ceil(sess.left * 100 / sess.capacity)
          let classNames = []
          switch (true) {
            case per >= 75:
              classNames = ['sess', 'sess-0']
              break
            case per >= 50 && per < 75:
              classNames = ['sess', 'sess-1']
              break
            case per >= 25 && per < 50:
              classNames = ['sess', 'sess-2']
              break
            case per >= 0 && per < 25:
              classNames = ['sess', 'sess-3']
              break
            case per === 0:
              classNames = ['sess', 'sess-4']
              break
            default:
              classNames = ['sess', 'sess-5']
          }
          return {
            start: d.day,
            display: 'background',
            classNames,
            title: d.day.slice(-2) }
        }
      })
    },
    isNext () {
      return this.nextCalc(this.activeStep)
    }
  },

  methods: {
    ...mapActions(['']),
    nextCalc (step) {
      return (step < 1 && this.change.day !== '') ||
      (step < 2 && this.change.session.idx !== -1)
    },
    selectStep (idx) {
      this.activeStep = idx
    },
    onClickStep (e, index) {
      if (index === 0 || this.nextCalc(index - 1)) {
        this.selectStep(index)
      }
    },
    resizeOpenNav () {
      this.innerWidth = window.innerWidth
      this.innerHeight = window.innerHeight
    },
    selectDate ({ day, isSame }) {
      this.change.day = day
      if (!isSame) {
        this.change.session.idx = -1
        this.change.session.name = ''
        this.change.session.time = ''
      }
      this.activeStep = 1
    },
    selectSession ({ name, time, idx }) {
      this.change.session.idx = idx
      this.change.session.name = name
      this.change.session.time = time
      this.activeStep = 2
    },
    setChange () {
      this.change = {
        id: this.current._id,
        day: this.current.day,
        session: {
          idx: this.current.session.idx,
          name: this.current.session.name,
          time: this.current.session.time
        }
      }
    }
  },

  activated () {
    this.resizeOpenNav()
    this.setChange()
    window.addEventListener('resize', this.resizeOpenNav)
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  },
  deactivated () {
    this.activeStep = 0
    window.removeEventListener('resize', this.resizeOpenNav)
  }
}
</script>
